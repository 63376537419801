import Swiper from "swiper/bundle";
//import { addDotBtnsAndClickHandlers } from "./dots";

class HeroSlider extends HTMLElement {
  constructor() {
    super();

    this.state = {};

    this.hasDots = null;
    this.dotsNode = {};
    this.removeDots = null;
    this.slideNames = this.getAttribute("data-dot_names");
    this.isVertical = this.hasAttribute("hero-slider-vertical");
    this.sectionIndex = this.getAttribute("data-section-index");

    this.handleSlideFocusIn = this.handleSlideFocusIn.bind(this);
    this.resetScrollPosition = this.resetScrollPosition.bind(this);
    this.setup = this.setup.bind(this);
    this.teardown = this.teardown.bind(this);

    this.WheelHandler = this.WheelHandler.bind(this);
    this.ScrollHandler = this.ScrollHandler.bind(this);
    this.topLockPos = 0;
    this.wheelDelta = 0;
    this.scrollY = 0;
    this.swiper_enabled = false;
    this.transitioning = false;

    this.setup();

    this.parentElement.addEventListener("shopify:section:unload", () => {
      this.teardown();
    });
    this.parentElement.addEventListener("shopify:section:load", () => {
      this.setup();
    });
    this.parentElement.addEventListener("shopify:block:select", (event) => {
      this.swiper.scrollTo(
        [...event.target.parentElement.children].indexOf(event.target)
      );
    });
  }

  setup() {
    var slideNames = this.slideNames.split(",");
    if (this.isVertical && window.matchMedia("(min-width: 1024px)").matches) {
      this.topLockPos =
        this.sectionIndex > 1
          ? this.offsetTop - document.querySelector("site-header").offsetHeight
          : document.querySelector("div.site-announcement")?.clientHeight || 0;
      this.scrollY = window.screenY;
      this.swiper_enabled = false;
      window.addEventListener("wheel", this.WheelHandler);
      window.addEventListener("scroll", this.ScrollHandler);
    }

    if (this.isVertical) {
      this.swiper = new Swiper(this, {
        direction: "vertical",
        touchReleaseOnEdges: true,
        speed: 400,
        lazyPreloadPrevNext: 1,
        enabled: window.matchMedia("(max-width: 1023px)").matches,
        mousewheel: {
          thresholdDelta: 10,
          forceToAxis: true,
          sensitivity: 1,
          releaseOnEdges: true,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "bullets",
          bulletActiveClass: "underline",
          renderBullet: function (index, classNames) {
            return (
              '<span class="cursor-pointer underline-offset-4 ' +
              classNames +
              '">' +
              slideNames[index] +
              "</span>"
            );
          },
        },
      });
    } else {
      this.swiper = new Swiper(this, {
        speed: 400,
        autoplay: {
          delay: 5000,
        },
        scrollbar: {
          el: ".swiper-hero-scrollbar",
          draggable: true,
        },
        mousewheel: {
          forceToAxis: true,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "bullets",
          bulletActiveClass: "underline",
          renderBullet: function (index, classNames) {
            return (
              '<span class="cursor-pointer underline-offset-4 ' +
              classNames +
              '">' +
              slideNames[index] +
              "</span>"
            );
          },
        },
      });
    }

    this.swiper.on("slideChangeTransitionStart", () => {
      this.transitioning = true;
    });

    this.swiper.on("slideChangeTransitionEnd", () => {
      this.transitioning = false;
      let nextSlide = this.swiper.slides[this.swiper.activeIndex + 1];
      if (nextSlide) {
        if (nextSlide.getAttribute("slide-type") == "image") {
          let imgs = nextSlide.querySelectorAll("img");
          for (var i = 0; i < imgs.length; i++) {
            imgs[i].setAttribute("loading", "eager");
          }
        } else {
          let videos = nextSlide.querySelectorAll("video");
          for (var i = 0; i < videos.length; i++) {
            videos[i].load();
          }
        }
      }
    });

    this.swiper.on("afterInit", () => {
      let nextSlide = this.swiper.slides[this.swiper.activeIndex + 1];
      if (nextSlide) {
        if (nextSlide.getAttribute("slide-type") == "image") {
          let imgs = nextSlide.querySelectorAll("img");
          for (var i = 0; i < imgs.length; i++) {
            imgs[i].setAttribute("loading", "eager");
          }
        } else {
          let videos = nextSlide.querySelectorAll("video");
          for (var i = 0; i < videos.length; i++) {
            videos[i].load();
          }
        }
      }
    });

    this.swiper.on("slideChange", function () {
      let dot_color = this.slides[this.realIndex].getAttribute("dots-color");
      this.pagination.bullets.forEach((dot) => {
        dot.style.color = dot_color;
      });
    });

    // Lock horizontal scroll for parent element
    this.parentElement.addEventListener("scroll", this.resetScrollPosition);

    // Jump to focused slide when moving with keyboard
    this.addEventListener("focusin", this.handleSlideFocusIn);

    if (this.state.selectedScrollSnap) {
      this.swiper.slideTo(this.state.selectedScrollSnap);
    }
  }

  teardown() {
    if (this.swiper) {
      this.swiper.destroy();
      this.parentElement.removeEventListener("scroll", this.handleSlideFocusIn);
      this.removeEventListener("focusin", this.handleSlideFocusIn);
    }
  }

  resetScrollPosition() {
    this.parentElement.scrollTo(0, 0);
  }

  handleSlideFocusIn(event) {
    const slide = event.target.closest('[data-module="hero"]');
    const slideIndex = parseInt(slide.getAttribute("data-slide-index"), 10) - 1;

    this.swiper.scrollTo(slideIndex, true);
  }

  WheelHandler(e) {
    this.wheelDelta = e.wheelDelta;
    console.log("wheel");
    if (
      (this.scrollY > 40 && this.swiper_enabled) ||
      (this.scrollY < 40 && this.swiper_enabled)
    ) {
      window.scrollTo(0, this.topLockPos);
    }
    if (
      this.swiper.isBeginning &&
      this.wheelDelta > 0 &&
      this.transitioning == false
    ) {
      document.body.classList.remove("overflow-hidden");
      this.swiper.disable();
      this.swiper_enabled = false;
    }
    if (
      this.swiper.isEnd &&
      this.wheelDelta < 0 &&
      this.transitioning == false
    ) {
      this.swiper.disable();
      this.swiper_enabled = false;
      setTimeout(() => {
        document.body.classList.remove("overflow-hidden");
      }, 250);
    }
  }

  ScrollHandler(e) {
    this.scrollY = window.scrollY;
    console.log("scroll");
    if (this.swiper_enabled == true) {
      window.scrollTo(0, this.topLockPos);
    }
    if (
      window.scrollY >= this.topLockPos &&
      this.wheelDelta < 0 &&
      this.swiper.isBeginning
    ) {
      window.scrollTo(0, this.topLockPos);
      document.body.classList.add("overflow-hidden");
      this.swiper.enable();
      this.swiper_enabled = true;
    }
    if (
      window.scrollY <= this.topLockPos &&
      this.wheelDelta > 0 &&
      this.swiper.isEnd
    ) {
      window.scrollTo(0, this.topLockPos);
      document.body.classList.add("overflow-hidden");
      this.swiper.enable();
      this.swiper_enabled = true;
    }
  }
}

customElements.define("hero-slider", HeroSlider);
